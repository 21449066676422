<template>
  <el-dialog append-to-body
      :title="invoiceUpdate.action == 'details'? '票据详情' : '票据修改'"
      :visible.sync="visible"
      width="500px"
      @close="handleInvoiceUpdateCancel"
  >
    <div class="invoice-update">
      <el-form ref="invoiceUpdate" :model="invoiceUpdate" :rules="rules" label-position="top">
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="发票类型">
              <el-select :disabled="ifShow" v-model="invoiceUpdate.invoiceType" placeholder="请选择发票类型">
                <el-option
                    v-for="item in invoiceTypeSet"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                v-if="invoiceUpdate.invoiceType != 'PlaneInvoice' && invoiceUpdate.invoiceType != 'TrainInvoice'"
                justify="space-between">
          <el-col :span="11">
            <el-form-item label="发票号码" prop="invoiceNo">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.invoiceNo" placeholder="请输入发票号码" maxlength="8"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="发票代码" prop="invoiceCode">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.invoiceCode" placeholder="请输入发票代码" maxlength="12"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                v-else
                justify="space-between">
          <el-col :span="11">
            <el-form-item label="电子客票号码/21位码" prop="train21No">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.train21No" placeholder="请输入电子客票号码/21位码" maxlength="8"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="乘车人" prop="rider">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.rider" placeholder="请输入乘车人" maxlength="12"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="开票时间" prop="invoiceTime" placeholder="请输入开票时间">
              <el-date-picker
                  v-model="invoiceUpdate.invoiceTime"
                  type="date"
                  :disabled="ifShow"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerDisabled"
                  placeholder="请选择时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="票价" prop="amount">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.amount" placeholder="请输入票价" maxlength="6"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                v-if="invoiceUpdate.invoiceType == 'PassengerInvoice'"
                justify="space-between">
          <el-col :span="11">
            <el-form-item label="乘车人" prop="rider">
              <el-input :disabled="ifShow" v-model="invoiceUpdate.rider" placeholder="请输入乘车人" maxlength="8"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div slot="footer" v-if="ifShow" class="dialog-footer">
      <el-button type="primary" @click="handleInvoiceUpdateCancel">确 认</el-button>
    </div>
    <div slot="footer" v-else class="dialog-footer">
      <el-button @click="handleInvoiceUpdateCancel">取 消</el-button>
      <el-button type="primary" @click="handleInvoiceUpdateConfirm('invoiceUpdate')">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { tableStyle, changeMethods } from '@/util/mixins'
import { updateImageInvoice } from '@/service/invoice-center'
import RegexLib from '@/assets/js/regex-lib'

export default {
  name: 'OtherInvoiceDetail',
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoiceUpdate: {
      type: Object
    }
  },
  data () {
    return {
      rules: {
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入票价', trigger: 'blur' },
          {
            pattern: RegexLib.Amount,
            message: '请输入正确的票价'
          },
        ],
        rider: [
          { required: true, message: '请输入乘车人', trigger: 'blur' }
        ],
        train21No: [
          { required: true, message: '电子客票号码/21位码', trigger: 'blur' }
        ],
        isExistList: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },
      visible: false,
      updateVisible: false,
      invoiceTypeSet: [],
      pickerDisabled: {
        disabledDate (date) {
          return date.getTime() > Date.now()
        }
      },
    }
  },
  watch: {
    show () {
      this.visible = this.show
    }
  },
  created () {
    this.invoiceTypeSet = this.$store.state.enums.InvoiceType
  },
  computed: {
    ifShow () {
      return this.invoiceUpdate.action == 'details'
    },
  },
  methods: {
    /* 发票修改确认 */
    handleInvoiceUpdateConfirm (invoiceUpdate) {
      this.$refs[invoiceUpdate].validate((valid) => {
        if (valid) {
          updateImageInvoice(this.invoiceUpdate).then(res => {
            if (res.success) {
              this.visible = false
              this.$emit('save')
            }
          })
        } else {
          return false
        }
      })
    },
    /* 发票修改取消 */
    handleInvoiceUpdateCancel () {
      this.$refs.invoiceUpdate.resetFields()
      this.visible = false
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped lang="scss">
.invoice-update {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}
</style>
